@import '../../base.scss';

@keyframes scroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(calc(-100% - 20px));
    }
}

.container{
    box-sizing: border-box;
    background-color: $white;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $white;
    padding: 20px 0;
}


.icon{
    width: 60px;
    height: 60px;
}

///////////
.items_wrap {
    position: relative;
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: 20px;
}
    
.items {
    flex-shrink: 0;
    display: flex;
    gap: 20px;
    counter-reset: item;
    justify-content: space-around;
    min-width: 100%;
}

.item {
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: center;
    counter-increment: item;
    transition: all 0.1s ease-in-out;
}

.marquee {
    animation: scroll 20s linear infinite;
}

.items_wrap:hover .marquee {
    animation-play-state: paused;
}