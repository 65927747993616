@import '../../base.scss';


    .main {
        max-width: 100vw;
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }

    .overlay{
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(1, 15, 23, .3);
    }

    .box{
        max-width: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 50px;
    }

.container{
    box-sizing: border-box;
    max-width: 750px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
}


.item {
    color: $white;
    background-image: url(../../image/InitialPage/img_4.jpg);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
    background-attachment: fixed;
}

.title{
    font-family:'Lovelace';
    text-align: center;
    font-size: 66px;
    line-height: 1.2;
    font-weight: 400;
    margin: 0;
    position: relative;
}

.line{
    background: $element;
    height: 3px;
    width: 50px;
    margin: 30px 0 20px; 
}

.text{
    font-size: 26px;
    line-height: 1.4;
    font-weight: 400;
    text-align: center;
    margin: 0;
    color: $white;
    opacity: .8;
}

.link{
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: $white;
    width: 180px;
    height: 50px;
    margin: 30px 0 0;
    border-radius: 4px;
    background: linear-gradient(to left,#f6c478 0,#dea43e 50%,#f6c478 100%);
    background-size: 200%;

    &:hover{
        cursor: pointer;
        background-position: right;
        transition: .5s;
    }
}


@media(max-width: 930px){
    .title{
        max-width: 600px;
        font-size: 48px;
    }

    .text{
        font-size: 22px;
    }

    .container{
        padding:  0;
    }
}

@media(max-width: 768px){
    .main {
        justify-content: flex-start;
        align-items: center;
    }

    .box{
        justify-content: center;
        margin-left: 0;
    }

    .box_two{
        justify-content: center;
    }

    .title{
        font-size: 42px;
        max-width: 500px;
    }

    .text{
        font-size: 20px;
    }

    .line{
        background: $element;
        height: 2px;
        width: 30px;
        margin: 20px 0 10px; 
    }
}

@media(max-width: 425px){
    .box{
        max-width: 100%;
    }

    .title{
        max-width: 300px;
        font-size: 34px;
    }

    .link{
        width: 150px;
        height: 40px;
        font-size: 14px;
    }
}