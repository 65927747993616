
@font-face {
	font-family:'Lovelace';
	font-style:  normal;
	font-weight: 400;
	font-display: swap;
	src: url("LovelaceRegular.woff2") format("woff2"),
		url("LovelaceRegular.woff") format("woff");
}

@font-face {
	font-family:'Raleway';
	font-style:  normal;
	font-weight: 400;
	font-display: swap;
	src: url("RalewayRegular.woff2") format("woff2"),
		url("RalewayRegular.woff") format("woff");
}

@font-face {
	font-family:'Raleway';
	font-style:  normal;
	font-weight: 500;
	font-display: swap;
	src: url("Raleway-Medium.woff2") format("woff2"),
		url("Raleway-Medium.woff") format("woff");
}