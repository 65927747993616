@import '../../base.scss';

.main{
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(../../image/Advantages/img.webp);
    background-repeat: no-repeat;
    background-position: 50% center;
    background-size: cover;
    background-attachment: fixed;
    background-color: transparent;
    position: relative;
    padding: 0;
}

.overlay{
    @include main-column;
    background-color: rgba($black, .5);
    width: 100%;
    height: 100%;
}

.box{
    box-sizing: border-box;
    max-width: 1400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title{
    @include title;
    margin-bottom: 30px;
    color: $white;
}

.list{
    max-width: 1200px;
    margin-top: 20px;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-template-rows: 1fr;
    margin-top: 50px;
}

.item{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.item_box{
    width: 93px;
    height: 93px;
    border-radius: 50%;
    background: linear-gradient(to left,#f6c478 0,#dea43e 50%,#f6c478 100%);
    background-size: 200%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    &:hover{
        cursor: pointer;
        background-position: right;
        transition: .5s;
    }
}

.item_image{
    width: 50px;
}

.item_title{
    text-transform: uppercase;
    margin: 15px 0 0;
    color: $white;
    text-align: center;
    font-weight: 500;
}

.item_text{
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: $white;
    text-align: center;
    margin: 15px 0 0;
}


@media(max-width: 1020x){
    .list{
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}

@media(max-width: 500px){
    .list{
        margin-top: 30px;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}