@import '../../base.scss';

.main{
    @include main-column;
}

.box{
    @include box-column
}

.title{
    @include title;
}

.list{
    max-width: 1400px;
    margin-top: 20px;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-template-rows: 1fr;
    margin-top: 50px;
}

.item{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.item_box{
    width: 93px;
    height: 93px;
    border-radius: 50%;
    background: linear-gradient(to left,#f6c478 0,#dea43e 50%,#f6c478 100%);
    background-size: 200%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover{
        cursor: pointer;
        background-position: right;
        transition: .5s;
    }
}

.item_image{
    width: 50px;
}

.item_title{
    text-transform: uppercase;
    margin: 15px 0 0;
    color: $title;
    text-align: center;
    font-weight: 500;
}

.item_text{
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: $text-box;
    text-align: center;
    margin: 15px 0 0;
}


@media(max-width: 768px){
    .list{
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }
}

@media(max-width: 500px){
    .list{
        margin-top: 30px;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}