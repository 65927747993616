$white: #FFF;
$black: #252525;
$background-header:rgba(#2e3342,.6);
$background-footer: $black;
$background: $white;
$title: #2e3342;
$red:#CD2122; 
$text-box: #898989;
$element: #dea43e;
$box:  #eff3f4;
$box-input:  #f2fafa;
$blue: #0700ff;


@mixin main-column {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
    padding: 50px 20px;
    overflow: hidden;

    @media(max-width: 768px){
        padding: 40px 10px;
    }

    @media(max-width: 425px){
        padding: 30px 10px;
    }
}

@mixin box-column {
    box-sizing: border-box;
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin title {
    font-family:'Lovelace';
    text-align: center;
    font-weight: 400;
    font-size: 38px;
    line-height: 1.2;
    color: $title;
    margin: 0;

    @media(max-width: 768px){
        font-size: 30px;
    }

    @media(max-width: 425px){
            font-size: 24px;
        }
}

@mixin text {
    color: $white;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    margin: 10px 0 0;

    @media(max-width: 768px){
        font-size: 16px;
}
}

@mixin button{
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
    padding: 0;
    color: $white;
    width: 180px;
    height: 50px;
    margin: 30px 0 0;
    border-radius: 4px;
    background: linear-gradient(to left,#f6c478 0,#dea43e 50%,#f6c478 100%);
    background-size: 200%;

    &:hover{
        cursor: pointer;
        background-position: right;
        transition: .5s;
    }
}


@mixin list{
    max-width: 1200px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 5px;

    @media(max-width: 768px){
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 10px;
        }
}



