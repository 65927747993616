@import '../../base.scss';

.main{
    @include main-column;
}

.box{
    @include box-column
}

.title{
    @include title
}

.list{
    max-width: 1400px;
    margin-top: 20px;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-template-rows: 1fr;
}

.line{
    background: $element;
    height: 2px;
    width: 33px;
    margin: 15px 0 10px; 
}

.text{
    font-weight: 500;
    font-size: 17px;
    line-height: 28px;
    text-align: center;
    color: $text-box;
    margin: 0;
}

@media(max-width: 1100px){
    .list{
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
}

@media(max-width: 900px){
    .list{
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}

@media(max-width: 768px){
    .list{
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 20px;
    }
}