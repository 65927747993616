@import '../../base.scss';

.main{
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(../../image/Services/img_1.webp);
    background-repeat: no-repeat;
    background-position: top left;
    background-size: cover;
    background-attachment: fixed;
    background-color: transparent;
    position: relative;
    padding: 0;
}

.title{
    @include title;
    z-index: 1;
    margin: 0 0 30px 0;
    color: $white;
}

.box{
    @include main-column;
    background-color: rgba($black, .2);
}


.list{
    @include list
}

.item{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
    transition: .5s;

    &:hover{
        cursor: pointer;
        transform:scale(1.02);
        transition: .5s;
    }
}

.box_info{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $white;
    padding: 20px;
}

.item_image{
    width: 100%;
    aspect-ratio: 4/3; 
}

.item_title{
    font-size: 18px;
    line-height: 1.2;
    font-weight: 500;
    width: 100%;
    text-transform: uppercase;
    margin: 0;
    color: $black;
    text-align: left;
    margin-bottom: 10px;
}

.item_text{
    font-size: 16px;
    line-height: 1.2;
    font-weight: 400;
    margin: 0 0 10px 0;
    color: $text-box;
    text-align: left;
    height: 96px;
}

.button{
    width: 100%;
    height: 40px;
    border: 0;
    color: white;
    text-transform: uppercase;
    background: linear-gradient(to left,#f6c478 0,#dea43e 50%,#f6c478 100%);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 200%;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;
    border-radius: 6px;
    margin: 0;

    &:hover{
        cursor: pointer;
        background-position: right;
        transition: .5s;
    }
}


@media(max-width: 768px){
    .list{
        gap: 10px;
    }
}

@media(max-width: 540px){
    .item_text{
        height: auto;
        margin: 0 0 20px 0;
    }
}