@import '../../base.scss';

.hint{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    border: 1px solid rgba(0,0,0,.08);
    &:hover{
        cursor: pointer;
    }
}

.hint_container{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    background: #fcfcfc;
    box-shadow: inset 0px 4px 4px -2px rgba(0, 0, 0, .02);
    border: 1px solid rgba(0,0,0,.08);
}


.hint_box{
    display: flex;
    justify-content: space-between;
}

.hint_title{
    width: 100%;
    font-family:'Lovelace';
    font-weight: 400;
    font-size: 24px;
    line-height: 1.2;
    text-align: left;
    color: $title;
    margin: 0;
    color: $title;

    &:hover{
        color: $element;
    }
}

.hint_image{
    width: 14px;
    height: 11px;
    margin-left: 10px;
    transition: all 1s ease;
}

.hint_active{
    width: 14px;
    height: 11px;
    transition: all 1s ease;
    transform: rotate(-180deg);
}

.button{
    @include button
}

.text{
    box-sizing: border-box;
    max-width: 100%;
}

@media(max-width: 620px){
    .hint_title{
        display: flex;
        flex-direction: column;
        font-size: 18px;
        & span{
            font-size: 14px;
            margin: 0;
        }
    }
}