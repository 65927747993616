@import '../../base.scss';

.modal{
    position: relative;
    max-width: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $background-footer;
    box-sizing: border-box;
    padding: 40px 30px;
    font-family:  "Montserrat", "Arial ", sans-serif;
    color: $white;
    z-index: 20;
    overflow: hidden;
    border-radius: 16px;
    border: 1px solid $title;
}

.title{
    @include title;
    color: $white;

    & span {
        color: $element;
    }
}

.text{
    font-family:'Lovelace';
    font-weight: 400;
    font-size: 17px;
    line-height: 1;
    text-align: center;
    color: $element;
    margin: 15px 0 0;
    text-transform: uppercase;
}

.link{
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: $white;
    width: 180px;
    height: 50px;
    margin: 30px 0 0;
    border-radius: 4px;
    background: linear-gradient(to left,#f6c478 0,#dea43e 50%,#f6c478 100%);
    background-size: 200%;

    &:hover{
        cursor: pointer;
        background-position: right;
        transition: .5s;
    }
}

.button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-image: url(../../image/Header/close.svg);
    background-position: center;
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    transition: .5s;
    width: 30px;
    height: 30px;

    &:hover{
        cursor: pointer;
        transform: rotate(180deg);
        transition: all .5s;
    }
}

@media(max-width: 768px){
    .modal{
        max-width: 560px;
    }

    .text{
        font-size: 16px;
    }
}

@media(max-width: 580px){

    .modal{
        max-width: 480px;
    }

    .title{
        font-size: 24px;
    }
}

@media(max-width: 425px){

    .modal{
        padding: 30px 20px;
    }

    .title{
        font-size: 22px;
    }
}

