@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.container{
    max-width: 1440px;
    box-sizing: border-box;
    width: 100%;
    padding: 50px 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.box_info{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center
}


.title{
    @include title;
    text-align: left;
}

.line{
    background: $element;
    height: 2px;
    width: 33px;
    margin: 15px 0 10px; 
}

.text{
    color: $text-box;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.2;
    margin: 10px 0 0;
}

.image{
    width: 40%;
    margin: 0 20px 0 0;
    aspect-ratio: 1/1;
}


@media(max-width: 768px){

    .container{
        display: flex;
        flex-direction: column;
        align-items: center;   
        padding: 40px 10px 0;
    }

    .text{
        font-size: 16px;
    }

    .image{
        width: 100%;
        margin: 0 0 20px;
    }
    
    .box_info{
        display: flex;
        flex-direction: column;
        align-items: center;  
    }

}

@media(max-width: 425px){

    .container{
    padding: 30px 10px 0;
}
}