@import '../../base.scss';

.main{
    width: 100%;
    background-image: url(../../image/Discount/img_2.webp);
    background-repeat: no-repeat;
    background-position: 50% center;
    background-size: cover;
    background-attachment: fixed;
    background-color: transparent;
}

.overlay{
    @include main-column;
    background-color: rgba($black, .3);
    width: 100%;
    height: 100%;
}

.box{
    box-sizing: border-box;
    max-width: 1400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.container{
    @include box-column;
    box-sizing: border-box;
    max-width: 500px;
    width: 100%;
}

.title{
    font-family:'Lovelace';
    font-weight: 400;
    font-size: 38px;
    line-height: 1.2;
    text-align: left;
    color: $white;
    margin: 0 0 15px;
}

.text{
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    text-align: left;
    color: $white;
    margin: 0 0 15px;
}


.form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.box_input{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    height: 60px;
    background-color: $white;
    border-radius: 4px;
    padding: 0 25px;
    box-sizing: border-box;
    margin-top: 10px;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, .25);
    border: 1px solid $text-box;

    &:nth-child(1){
        margin-top: 0;
    }
}

.label{
    box-sizing: border-box;
    width: 100%;
    color: $text-box;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    margin-top: 8px; 
}

.input{
    box-sizing: border-box;
    width: 100%;
    background: none;
    border: none;
    outline: none;
    color: $text-box;
}

.wrong {
    color: $red;
}

.input_error{
    width: 100%;
    text-align: left;
    margin-top: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: $red;
    text-align: center;
}

.button_form{
    @include button;
}

.button_disabled{
    pointer-events: none;
    cursor: default;
}


@media(max-width: 425px){
    .title{
        font-size: 36px;
    }
}